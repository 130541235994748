// LandingPage.js
import { useState, useEffect } from 'react';
import { Box, Button, Text, Center, VStack, Flex, Image } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useRouter } from 'next/router';
import NavBar from './NavBar';
import CarrierNavBar from './CarrierNavBar';

const LandingPage = () => {
    const router = useRouter();
    const [queryParams, setQueryParams] = useState(new URLSearchParams());
    const [hasBOLParams, setHasBOLParams] = useState(false);
    const [hasCarrierPortalParams, setHasCarrierPortalParams] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setQueryParams(params);
        setHasBOLParams(params.has('load') && params.has('accessCode') && params.has('broker'));
        setHasCarrierPortalParams(params.has('MC') || params.has('DOT'));
    }, [router.asPath]);

    const navigateWithParams = (path, imageName) => {
        const queryParamsString = queryParams.toString();
        if (imageName) {
            queryParams.set('open', imageName);
        }
        router.push(`${path}?${queryParamsString}`);
    };

    // Custom background position adjustments
    const customBgPositions = {
        first: 'center 25%',  // Zoom out to show more of the vehicle
        second: 'center 30%', // Zoom out to show more of the excavator
        third: 'center 10%',  // Zoom out to show more of the firetruck
        fourth: 'center 20%', // Zoom out to show more of the dump truck
        fifth: 'center 10%'   // Zoom out for larger screens to show more of the bulldozer
    };

    return (
        <Box
            width="100vw"
            height="100vh"
            overflowY="scroll"
            sx={{
                '&::-webkit-scrollbar': { width: '0px' },
                scrollbarWidth: 'none',
                msOverflowStyle: 'none'
            }}
        >
            {hasCarrierPortalParams || hasBOLParams ? <CarrierNavBar /> : <NavBar />}
            <VStack spacing={0} align="stretch" fontSize={{ base: '16px', md: '20px' }}>
                <Box bg="#474C55" p={{ base: '5px', md: '20px' }}>
                    <Center><Image maxWidth={{ base: '150px', md: '250px' }} src="/heat-logo.png" alt="HEAT Logo" /></Center>
                </Box>
                <Flex direction={{ base: 'column', md: 'row' }} bg="white" p={{ base: '10px', md: '20px' }} align="center" justify="space-around" wrap="wrap">
                    <Box
                        bgImage="url('/gallery/MRAP_Cougar_Mine-Resistant_Ambush_Protected_Military_Truck.jpeg')"
                        bgPosition={customBgPositions.first}
                        bgRepeat="no-repeat"
                        bgSize="cover"
                        h={{ base: '150px', md: '200px' }} // Increase height for smaller screens
                        w={{ base: '100%', md: '30%' }}
                        onClick={() => navigateWithParams('/gallery', 'MRAP_Cougar_Mine-Resistant_Ambush_Protected_Military_Truck.jpeg')}
                        cursor="pointer"
                    />
                    <VStack spacing={4} w={{ base: '100%', md: '40%' }} align="center">
                        <Text fontFamily='-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen' textAlign="center">
                            Heavy Equipment Auction Transport specializes in shipping agricultural and construction machinery nationwide.</Text>
                        <Text textAlign="center">HEAT Freight offers a customized end to end solution to get your equipment moved.</Text>
                        <Text textAlign="center">{"We've"} developed a simple turn-key solution for transporting any kind of heavy equipment.</Text>
                        <Text textAlign="center">We pride ourselves in providing accurate and honest quotes to our customers.</Text>
                        <Button
                            onClick={() => navigateWithParams('/callback')}
                            variant={'solid'}
                            bg={'#FDB515'}
                            size={'sm'}
                            fontSize='18px'
                            _active={{
                                bg: '#dddfe2',
                                transform: 'scale(0.98)',
                                borderColor: '#bec3c9',
                            }}
                            color='#474C55'
                            leftIcon={<InfoOutlineIcon />}
                        >
                            Request A Free Freight Quote
                        </Button>
                    </VStack>
                    <Box
                        bgImage="url('/gallery/CAT_EL240B_Hydraulic_Excavator_from_Ritchie_Bros._Longmont_Colorado.jpeg')"
                        bgPosition={customBgPositions.second}
                        bgRepeat="no-repeat"
                        bgSize="cover"
                        h={{ base: '150px', md: '200px' }} // Increase height for smaller screens
                        w={{ base: '100%', md: '30%' }}
                        onClick={() => navigateWithParams('/gallery', 'CAT_EL240B_Hydraulic_Excavator_from_Ritchie_Bros._Longmont_Colorado.jpeg')}
                        cursor="pointer"
                    />
                </Flex>
                <Flex direction={{ base: 'column', md: 'row' }} minHeight="33vh" cursor="pointer">
                    <Box
                        bgImage="url('/gallery/Mack_Firetruck.jpeg')"
                        bgPosition={customBgPositions.third}
                        bgRepeat="no-repeat"
                        bgSize="cover"
                        flex="1"
                        minHeight="150px" // Set a minimum height for smaller screens
                        onClick={() => navigateWithParams('/gallery', 'Mack_Firetruck.jpeg')}
                    />
                    <Box
                        bgImage="url('/gallery/Caterpillar_CAT_735_Articulated_Dump_Truck_from_Ritchie_Bros.jpeg')"
                        bgPosition={customBgPositions.fourth}
                        bgRepeat="no-repeat"
                        bgSize="cover"
                        flex="1"
                        minHeight="150px" // Set a minimum height for smaller screens
                        onClick={() => navigateWithParams('/gallery', 'Caterpillar_CAT_735_Articulated_Dump_Truck_from_Ritchie_Bros.jpeg')}
                    />
                    <Box
                        flex="1"
                        minHeight="150px" // Set a minimum height for smaller screens
                        onClick={() => navigateWithParams('/gallery', 'Caterpillar_CAT_D7R_Bulldozer_from_Ritchie_Bros.jpg')}
                    >
                        <Image
                            src="/gallery/Caterpillar_CAT_D7R_Bulldozer_from_Ritchie_Bros.jpg"
                            alt="Caterpillar CAT D7R Bulldozer from Ritchie Bros"
                            objectFit="cover"
                            width="100%"
                            height="100%"
                            srcSet={{
                                base: "/gallery/Caterpillar_CAT_D7R_Bulldozer_from_Ritchie_Bros_mobile.jpg",
                                md: "/gallery/Caterpillar_CAT_D7R_Bulldozer_from_Ritchie_Bros.jpg"
                            }}
                            sizes={{
                                base: "100vw",
                                md: "33vw"
                            }}
                        />
                    </Box>
                </Flex>
            </VStack>
        </Box>
    );
}

export default LandingPage;
