// CallToActionButton.js
import { Button } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useRouter } from 'next/router'; // Import useRouter

export default function CallToActionButton() {
    const router = useRouter(); // Initialize useRouter for navigation

    // Function to navigate while retaining URL parameters
    const navigateWithParams = () => {
        const queryParams = new URLSearchParams(window.location.search).toString();
        router.push(`/callback?${queryParams}`);
    };

    return (
        <Button
            onClick={navigateWithParams} // Updated to use navigateWithParams
            marginLeft='10px'
            marginRight='-15px'
            variant={'solid'}
            bg={'#FDB515'}
            size={'sm'}
            color='#474C55'
            mr={4}
            leftIcon={<InfoOutlineIcon />}
        >
            Request A Quote
        </Button>
    );
}
