// NavBar.js
import { ReactNode, useCallback, useState } from 'react';
import { Box, Center, Flex, Image, HStack, Link, IconButton, Button, Menu, MenuButton, MenuList, MenuItem, MenuDivider, useDisclosure, useColorModeValue, Stack, VStack, Text } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useRouter } from 'next/router';
import CallToActionButton from './CallToActionButton';

const Links = ['(205) 440-2033', '(720) 441-4328'];

const NavLink = ({ children, onClick, isActive }) => {
    const buttonBg = useColorModeValue('gray.200', 'gray.700');
    const buttonColor = useColorModeValue('black', 'white');
    const inactiveColor = useColorModeValue('gray.600', 'gray.300');

    return (
        <Button
            px={2}
            py={1}
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
                bg: isActive ? buttonBg : 'transparent',
            }}
            onClick={onClick}
            variant={'ghost'}
            bg={isActive ? buttonBg : 'transparent'}
            color={isActive ? buttonColor : inactiveColor}
        >
            {children}
        </Button>
    );
};

export default function NavBar() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const router = useRouter();

    const [currentTab, setCurrentTab] = useState('');

    const navigateWithParams = useCallback((path) => {
        const queryParams = new URLSearchParams(window.location.search).toString();
        router.push(`/${path}?${queryParams}`);
        setCurrentTab(path);
    }, [router]);

    const isActive = (path) => {
        return router.pathname === `/${path}`;
    };

    return (
        <>
            <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'} marginRight='-10px'>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        marginLeft='-15px'
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={8} alignItems={'center'}>
                        <Box cursor="pointer" onClick={() => navigateWithParams('')}>
                            <Image src="/logo.png" alt="" marginRight="10px" height="30px" />
                        </Box>
                        <HStack
                            as={'nav'}
                            spacing={4}
                            display={{ base: 'none', md: 'flex' }}>
                            {Links.map((link) => (
                                <a href={`tel:+${link}`} key={link}>{link}</a>
                            ))}
                            <NavLink onClick={() => navigateWithParams('meet-the-team')} isActive={isActive('meet-the-team')}>
                                Meet The Team
                            </NavLink>
                            <NavLink onClick={() => navigateWithParams('gallery')} isActive={isActive('gallery')}>
                                Gallery
                            </NavLink>
                            <NavLink onClick={() => navigateWithParams('aboutUs')} isActive={isActive('aboutUs')}>
                                About Us
                            </NavLink>
                            <NavLink onClick={() => navigateWithParams('permitMap')} isActive={isActive('permitMap')}>
                                Permit Map
                            </NavLink>
                            <NavLink onClick={() => navigateWithParams('contact')} isActive={isActive('contact')}>
                                Contact
                            </NavLink>
                        </HStack>
                    </HStack>
                    <Flex alignItems={'center'}>
                        <CallToActionButton />
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {Links.map((link) => (
                                <NavLink key={link} href={`tel:+${link}`} isActive={false}>{link}</NavLink>
                            ))}
                            <NavLink onClick={() => navigateWithParams('meet-the-team')} isActive={isActive('meet-the-team')}>
                                Meet The Team
                            </NavLink>
                            <NavLink onClick={() => navigateWithParams('gallery')} isActive={isActive('gallery')}>
                                Gallery
                            </NavLink>
                            <NavLink onClick={() => navigateWithParams('aboutUs')} isActive={isActive('aboutUs')}>
                                About Us
                            </NavLink>
                            <NavLink onClick={() => navigateWithParams('permitMap')} isActive={isActive('permitMap')}>
                                Permit Map
                            </NavLink>
                            <NavLink onClick={() => navigateWithParams('contact')} isActive={isActive('contact')}>
                                Contact
                            </NavLink>
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </>
    );
}