import '../styles/globals.css';
import { ChakraProvider, Button, Center } from '@chakra-ui/react';
import NavBar from '../components/NavBar';
import LandingPage from '../components/LandingPage';

function MyApp({ Component, pageProps }) {
  return (
    <ChakraProvider>
      <Component {...pageProps} />
    </ ChakraProvider>
         );
}

export default MyApp;
