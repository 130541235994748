// CarrierNavBar.js v0.007.42.69.021
import { useCallback, useState, useEffect } from 'react';
import { Box, Flex, Image, HStack, IconButton, useDisclosure, useColorModeValue, Stack, Button, Text, Code } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useRouter } from 'next/router';

const Links = ['(205) 440-2033', '(720) 441-4328'];

const NavLink = ({ children, onClick, isSelected }) => {
    const buttonBg = useColorModeValue('gray.200', 'gray.700');
    const buttonColor = useColorModeValue('black', 'white');
    const inactiveColor = useColorModeValue('gray.600', 'gray.300');

    return (
        <Button
            px={2}
            py={1}
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
                bg: isSelected ? buttonBg : 'transparent',
            }}
            onClick={onClick}
            variant={isSelected ? 'solid' : 'ghost'}
            bg={isSelected ? buttonBg : 'transparent'}
            color={isSelected ? buttonColor : inactiveColor}
        >
            {children}
        </Button>
    );
};

export default function CarrierNavBar({ initialQuery = {}, showDebug = false }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const router = useRouter();

    const [queryParams, setQueryParams] = useState(new URLSearchParams(initialQuery));
    const [hasCarrierPortalParams, setHasCarrierPortalParams] = useState(false);
    const [isSetupMode, setIsSetupMode] = useState(false);
    const [isBOLMode, setIsBOLMode] = useState(false);
    const [debugInfo, setDebugInfo] = useState({
        initialQuery,
        pathname: router.pathname,
        asPath: router.asPath,
        query: router.query,
        isReady: router.isReady,
    });

    useEffect(() => {
        if (!router.isReady) return;

        const params = new URLSearchParams(window.location.search);
        setQueryParams(params);

        const carrierPortalParams = (params.has('MC') || params.has('DOT')) && params.get('load');
        setHasCarrierPortalParams(carrierPortalParams);

        const setupMode = params.get('heatMode') === 'setup';
        setIsSetupMode(setupMode);

        const bolMode = params.get('heatMode') === 'bol';
        setIsBOLMode(bolMode);

        setDebugInfo(prevDebugInfo => ({
            ...prevDebugInfo,
            pathname: router.pathname,
            asPath: router.asPath,
            query: router.query,
            isReady: router.isReady,
            hasCarrierPortalParams: carrierPortalParams,
            isSetupMode: setupMode,
            isBOLMode: bolMode
        }));
    }, [router]);

    const navigateWithParams = useCallback((path) => {
        const currentParams = new URLSearchParams(window.location.search);

        // Ensure 'addressusa' parameters are correctly formatted
        ['addr_line1', 'addr_line2', 'city', 'state', 'postal'].forEach(param => {
            if (currentParams.has(param)) {
                const value = currentParams.get(param);
                currentParams.delete(param);
                currentParams.append(`addressusa[${param}]`, value);
            }
        });

        const fullPath = `/${path}${currentParams.toString() ? `?${currentParams.toString()}` : ''}`;
        router.push(fullPath);
    }, [router]);

    const isActive = useCallback((path) => {
        return router.pathname === `/${path}`;
    }, [router.pathname]);

    return (
        <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
            <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                <IconButton
                    size={'md'}
                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    aria-label={'Open Menu'}
                    display={{ md: 'none' }}
                    onClick={isOpen ? onClose : onOpen}
                />
                <HStack spacing={8} alignItems={'center'}>
                    <Box cursor="pointer" onClick={() => navigateWithParams('')}>
                        <Image src="/logo.png" alt="" height="30px" />
                    </Box>
                    <HStack
                        as={'nav'}
                        spacing={4}
                        display={{ base: 'none', md: 'flex' }}>
                        {Links.map((link) => (
                            <a href={`tel:+${link}`} key={link}>{link}</a>
                        ))}
                        {!hasCarrierPortalParams && (
                            <NavLink onClick={() => navigateWithParams('billing')} isSelected={isActive('billing')}>
                                Billing
                            </NavLink>
                        )}
                        <NavLink onClick={() => navigateWithParams('permitMap')} isSelected={isActive('permitMap')}>
                            Permit Map
                        </NavLink>
                        {isSetupMode && (
                            <NavLink onClick={() => navigateWithParams('setup')} isSelected={isActive('setup')}>
                                Setup
                            </NavLink>
                        )}
                        {isBOLMode && (
                            <NavLink onClick={() => navigateWithParams('bol')} isSelected={isActive('bol')}>
                                BOL
                            </NavLink>
                        )}
                        {hasCarrierPortalParams && (
                            <NavLink onClick={() => navigateWithParams('carrierportal')} isSelected={isActive('carrierportal')}>
                                Carrier Portal
                            </NavLink>
                        )}
                        <NavLink onClick={() => navigateWithParams('contact')} isSelected={isActive('contact')}>
                            Contact
                        </NavLink>
                    </HStack>
                </HStack>
            </Flex>

            {isOpen && (
                <Box pb={4} display={{ md: 'none' }}>
                    <Stack as={'nav'} spacing={4}>
                        {Links.map((link) => (
                            <NavLink key={link} href={`tel:+${link}`}>{link}</NavLink>
                        ))}
                        {!hasCarrierPortalParams && (
                            <NavLink onClick={() => navigateWithParams('billing')} isSelected={isActive('billing')}>
                                Billing
                            </NavLink>
                        )}
                        <NavLink onClick={() => navigateWithParams('permitMap')} isSelected={isActive('permitMap')}>
                            Permit Map
                        </NavLink>
                        {isSetupMode && (
                            <NavLink onClick={() => navigateWithParams('setup')} isSelected={isActive('setup')}>
                                Setup
                            </NavLink>
                        )}
                        {isBOLMode && (
                            <NavLink onClick={() => navigateWithParams('bol')} isSelected={isActive('bol')}>
                                BOL
                            </NavLink>
                        )}
                        {hasCarrierPortalParams && (
                            <NavLink onClick={() => navigateWithParams('carrierportal')} isSelected={isActive('carrierportal')}>
                                Carrier Portal
                            </NavLink>
                        )}
                        <NavLink onClick={() => navigateWithParams('contact')} isSelected={isActive('contact')}>
                            Contact
                        </NavLink>
                    </Stack>
                </Box>
            )}

            {showDebug && (
                <Box mt={4} p={2} bg="gray.100" borderRadius="md">
                    <Text fontWeight="bold">Debug Info:</Text>
                    <Code display="block" whiteSpace="pre" overflowX="auto">
                        {JSON.stringify(debugInfo, null, 2)}
                    </Code>
                </Box>
            )}
        </Box>
    );
}
